/* Typography */
.font-display01-light {
  font-weight: var(--fontWeightLight);
  font-size: var(--fontSizeDisplay01);
  line-height: var(--lineHeightDisplay01);
  letter-spacing: 0.5px;
}

.font-display02-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeDisplay02);
  line-height: var(--lineHeightDisplay02);
}

.font-display03-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeDisplay03);
  line-height: var(--lineHeightDisplay03);
}

.font-helper-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHelper);
  line-height: var(--lineHeightHelper);
}

.font-helper-italic {
  font-style: italic;
  font-size: var(--fontSizeHelper);
  line-height: var(--lineHeightHelper);
}

.font-helper-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeHelper);
  line-height: var(--lineHeightHelper);
}

.font-caption-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeCaption);
  line-height: var(--lineHeightCaption);
  letter-spacing: 0.5px;
}

.font-caption-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeCaption);
  line-height: var(--lineHeightCaption);
  letter-spacing: 0.5px;
}

.font-overline-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeOverline);
  line-height: var(--lineHeightOverline);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.font-overline-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeOverline);
  line-height: var(--lineHeightOverline);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.font-body01-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeBody01);
  line-height: var(--lineHeightBody01);
}

.font-body01-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeBody01);
  line-height: var(--lineHeightBody01);
}

.font-body02-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeBody02);
  line-height: var(--lineHeightBody02);
}

.font-body02-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeBody02);
  line-height: var(--lineHeightBody02);
}

.font-body03-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeBody03);
  line-height: var(--lineHeightBody03);
}

.font-body03-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeBody03);
  line-height: var(--lineHeightBody03);
}

.font-heading01-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHeading01);
  line-height: var(--lineHeightHeading01);
}

.font-heading02-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHeading02);
  line-height: var(--lineHeightHeading02);
}

.font-heading03-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHeading03);
  line-height: var(--lineHeightHeading03);
}

.font-heading04-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHeading04);
  line-height: var(--lineHeightHeading04);
}

.font-heading05-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHeading05);
  line-height: var(--lineHeightHeading05);
}

.font-heading05-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeHeading05);
  line-height: var(--lineHeightHeading05);
}

.font-heading06-regular {
  font-weight: var(--fontWeightRegular);
  font-size: var(--fontSizeHeading06);
  line-height: var(--lineHeightHeading06);
}

.font-heading06-semibold {
  font-weight: var(--fontWeightSemibold);
  font-size: var(--fontSizeHeading06);
  line-height: var(--lineHeightHeading06);
}