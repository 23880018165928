@import '@mercadona/styles/css/mercadona-typography.css'; /* You can add global styles to this file, and also import other style files */
@import '@mercadona/styles/sass/variables';
@import 'bootstrap/scss/mixins/_breakpoints';

$blue: #0066ff;
$grid-breakpoints: (
  xxs: 0,
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

.cdk-overlay-container {
  z-index: 100000;
}

.section-header {
  width: 100%;
}

.debug {
  &-border {
    border: gray 1px solid !important;
  }
}

.white-space {
  white-space: nowrap;
}

.highlighted {
  border-top: 4px solid #0f803e;
  @include media-breakpoint-down(xs) {
    border-top: 4px solid #0f803e;
  }
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.full-content {
  width: 100% !important;
  height: 100% !important;
}

.filter-options {
  position: sticky;
  top: 12em;
  background-color: white;
  z-index: 200;

  @include media-breakpoint-down(md) {
    top: 8em;
  }

  @include media-breakpoint-down(sm) {
    top: 0em;
  }

  .filter-options-advanced {
    background-color: var(--range11_05);
  }
}

td.m-cell {
  overflow: hidden;
  word-wrap: break-word;
  min-height: 3.5rem;
  min-width: 3.5rem;
  padding: 1rem;
  background: inherit;
}

th.no-width-no-padding,
td.no-width-no-padding {
  width: 0;
  border-right: none !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  min-width: auto !important;
}

td.m-cell:not(:last-of-type) {
  border-right: 1px solid var(--activeUi);
}

.stage {
  &.options {
    @include media-breakpoint-up(xxs) {
      flex-direction: row;
    }
    flex-direction: column;
  }
}

.if-breakpoint {
  @include media-breakpoint-up(sm) {
    &.up-sm-block {
      display: block !important;
    }
    &.up-sm-flex {
      display: flex !important;
    }
    &.down-sm-block {
      display: none !important;
    }
    &.down-sm-flex {
      display: none !important;
    }
  }
  &.up-sm-block {
    display: none;
  }
  &.up-sm-flex {
    display: none;
  }
  &.down-sm-block {
    display: block;
  }
  &.down-sm-flex {
    display: flex;
  }
}
.row {
  --bs-gutter-x: 0 !important;
}

.occupancy-block {
  flex-direction: row !important;
  @include media-breakpoint-down(xs) {
    flex-direction: column !important;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column !important;
  }
  @include media-breakpoint-up(md) {
    flex-direction: column !important;
  }
}

.viewport-scroll {
  @include media-breakpoint-down(sm) {
    height: calc(100vh - 182px) !important;
  }
  @include media-breakpoint-up(md) {
    height: calc(100vh - 250px) !important;
  }
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: auto;
}

.body-scroll-lock {
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.m-dialog-container .m-dialog-card-actions {
  box-shadow: inset 0 0 var(--field04) !important;
}

.m-dialog-card-container .m-dialog-card-content {
  display: block !important;
}

.m-dialog-card-content {
  line-height: normal;
}

.dialog {
  width: 50%;
  max-width: 50%;
  max-height: 100%;
  overflow-y: hidden;

  &.no-size {
    height: auto;
  }

  .m-dialog-card-container {
    padding: 0 !important;
  }

  .m-dialog-card-header {
    padding: 24px 32px 16px;
    margin: 0 !important;
  }

  .m-dialog-card-content {
    max-height: calc(var(--vh, 1vh) * 70);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 32px 0 32px;
  }

  .m-dialog-card-actions {
    padding: 16px 32px;
    margin: 0 !important;
  }

  @media (max-width: 1023px) {
    width: 90%;
    max-width: 90%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 100%;

    .m-dialog-card {
      overflow-y: hidden;
      height: calc(var(--vh, 1vh) * 100) !important;
      max-height: calc(var(--vh, 1vh) * 100) !important;
    }

    .m-dialog-container {
      padding: 0 !important;
      overflow-y: hidden;
      height: calc(var(--vh, 1vh) * 100) !important;
      max-height: calc(var(--vh, 1vh) * 100) !important;
    }

    .m-dialog-card-header {
      height: calc(var(--vh, 1vh) * 15);
    }

    .m-dialog-card-content {
      height: calc(((var(--vh, 1vh) * 73)) - 72px) !important;
      max-height: calc(((var(--vh, 1vh) * 73)) - 72px) !important;
    }

    .m-dialog-card-actions {
      height: calc(var(--vh, 1vh) * 12);
    }
  }
}

.paginator-zoom {
  zoom: 1;
}
@include media-breakpoint-down(md) {
  .paginator-zoom {
    zoom: 0.8;
  }
}
@include media-breakpoint-down(sm) {
  .paginator-zoom {
    zoom: 0.6;
  }
}

m-dialog-card-actions {
  button {
    margin-left: auto !important;
    &:last-child {
      margin-left: 1rem !important;
    }
    &:nth-child(1) {
      margin-left: auto !important;
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: grab;
}

.clear-btn {
  width: 16px;
  height: 16px;
  margin: 16px;
}

.max-height-none {
  max-height: none !important;
}
.height-auto {
  height: auto !important;
}
.width-max-content {
  width: max-content;
}

.scroll-page {
  overflow-y: auto;
  height: calc(calc(var(--vh, 1vh) * 100) - 12em);

  @media (max-width: 64em) {
    height: calc(calc(var(--vh, 1vh) * 100) - 7.5em);
  }

  @media (max-width: 48em) {
    height: calc(calc(var(--vh, 1vh) * 100) - 7em);
  }
}

.fields-group {
  background-color: var(--range11_05);
  gap: 0em 2em;
}

m-alert.m-alert.m-alert-info {
  height: fit-content;
}

.m-html-editor-header .m-form-field-small {
  min-width: 3rem !important;
}

m-checkbox.m-checkbox .m-checkbox-container .m-checkbox-label > * {
  color: var(--bodyColor);
}

m-sidenav-container {
  &.m-sidenav-transition,
  &.m-sidenav-container-has-open {
    z-index: 1000;

    m-sidenav-backdrop.m-sidenav-shown {
      position: fixed;
      z-index: 3;
    }
  }

  m-sidenav.m-sidenav-over {
    width: 25%;

    @include media-breakpoint-down(lg) {
      width: 40%;
    }

    @include media-breakpoint-down(md) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 60%;
    }
  }
}
